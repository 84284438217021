import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { saveAs } from 'file-saver';

class Networks extends Component {


  onPressedDownload = () => {
    saveAs("https://firebasestorage.googleapis.com/v0/b/suntec-f5bcf.appspot.com/o/help%2FSuntecCMS-UserManual-18Jan2021%20(2).pdf?alt=media&token=1f4488c7-0564-4867-9211-978b428b1ba5", "SuntecCMS-UserManual-Aug20.pdf");

  }

  render() {
    return (
      <div style={{ paddingLeft: 20 }}>
        <h2>User Guide for Suntec City CMS Interactive Directories & Digital Signage at Lobbies</h2>
        <Button style={{ width: 200, marginTop: 20 }} variant="outlined" color="default" onClick={()=> this.onPressedDownload()}>
            Download
        </Button>
      </div>
    );
  }
}
  
export default Networks;