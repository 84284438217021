import React, { Component } from "react";
import app from "../../firebase/base";
import Reaptcha from 'reaptcha';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Link } from "react-router-dom";
import logo from '../../assets/logo.png';
import { directoryMode } from '../../firebase/config';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    width: 300,
    backgroundColor: '#F4F4F4'
  },
  textField: {
    marginTop: 10
  },
  title: {
    fontWeight: 'bold'
  }
});

class SignIn extends Component {

  constructor(props) {
    super(props);
    this.captcha = null;
    this.state = {
      isVerified: false,
      email: '',
      password: ''
    }
  }

  onLoad = () => {
    console.log('capcha successfully loaded');
  };

  onVerify = async () => {
    // Do something
    this.setState({
      isVerified: true
    });

    const { email, password } = this.state;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email, password);
      } catch (error) {
        alert(error);
      }
  };

  onExpire = () => {
    // Do something
    this.setState({
      isVerified: false
    });
  };
  

  handleSignIn = async event => {
    event.preventDefault();

    if (this.state.isVerified) {
      const { email, password } = this.state;
      try {
        await app
          .auth()
          .signInWithEmailAndPassword(email, password);
      } catch (error) {
        alert(error);
      }
    } else {
      alert('Please verify that you are a human!');
    }
    
    
  };

  handleChange = event => {
    this.setState({ email: event.target.value });
  };

  passwordhandleChange = event => {
    this.setState({ password: event.target.value });
  };

  render() {

    const { classes } = this.props;

    return (
      <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'  }}>
        <Paper className={classes.root} elevation={1} >
        <br/>
          <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center' }}>
            <img width="150" src={logo} alt="Logo" />
          </div>
          <br/>
          {directoryMode[0].mode ? <Typography style={{ padding: 30, fontSize: 18, textAlign: 'center', fontWeight:'bold' }} >
          Interactive Directory Login
          </Typography> : 
          <Typography style={{ padding: 30, fontSize: 18, textAlign: 'center', fontWeight:'bold' }} >
          Digital Signage Login
    </Typography> }
          <form autoComplete="on" onSubmit={this.handleSignIn} >
            <TextField
              fullWidth
              autoFocus
              margin="dense"
              id="standard-name"
              label="Username"
              type="email"
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange}
            />

            <TextField
              fullWidth
              id="standard-password-input"
              label="Password"
              className={classes.textField}
              type="password"
              value={this.state.password}
              onChange={this.passwordhandleChange}
              margin="dense"
            />

            <div style={{ marginTop: 20 }} >
              <Reaptcha sitekey="6Ld1gZ8UAAAAAAav2MZF1t6n77Lw4oDj3HqQYQuh" onVerify={this.onVerify} onExpire={this.onExpire}/>
            </div>
            <div style={{ marginTop: 20 }} >
              <Button variant="outlined" type="submit" disabled={!this.state.isVerified}>Log in</Button>
            </div>

            <br/>
            <Typography style={{ marginTop: 20 }} variant="caption" className={classes.title} component={Link} to={'/forgotpassword'} >Forgot Password</Typography>
            <br/>
            <Typography style={{ marginTop: 20 }} variant="caption" >Copyright by PGK Digital Networks</Typography>

            <br/>
            <Typography style={{ marginTop: 0 }} variant="caption">
              <a target="_blank" href="https://www.pgkdigital.com/">
                  http://www.pgkdigital.com
              </a>
            </Typography>
          </form>
        </Paper>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
