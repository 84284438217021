// import Calendar Component 
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    datePicker: {
       
    }
});

class DayPicker extends Component {

    render() {
        return (
            <Grid container>
                    <Grid container>
                        <Grid item xs={10} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                            <Typography style={{ marginTop: 10 }} variant="caption" display="block" gutterBottom>
                                Schedule
                            </Typography>
                        </Grid>
                        <Grid item xs={2} >
                            <Switch checked={this.props.switchChecked} onChange={this.props.handleSwitchChange} />
                        </Grid>
                        
                    </Grid>

                    <Typography style={{ marginTop: 10 }} variant="caption" display="block" gutterBottom>
                        Date [ Start ---- End ]
                    </Typography>
                    <Grid container>
                        <Grid item xs={5} style={{ paddingRight: 5}}>
                            <Button disabled={!this.props.switchChecked} fullWidth variant="outlined" color="primary" onClick={this.props.openDialogStartDate}>
                            {this.props.selectedStartDate.format("YYYY-MM-DD")}
                            </Button>
                        </Grid>
                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography style={{ color: 'gray' }} variant="caption" display="block" gutterBottom>
                            ------
                            </Typography>
                        </Grid>
                        <Grid item xs={5} style={{ paddingLeft: 5}}>
                            <Button disabled={!this.props.switchChecked} fullWidth variant="outlined" color="primary" onClick={this.props.openDialogEndDate}>
                            {this.props.selectedEndDate.format("YYYY-MM-DD")}
                            </Button>
                        </Grid>
                        
                    </Grid>

                    <Typography style={{ marginTop: 15 }} variant="caption" display="block" gutterBottom>
                        Time [ Start ---- End ]
                    </Typography>
                    <Grid container>
                        <Grid item xs={5} style={{ paddingRight: 5}}>
                            <Button disabled={!this.props.switchChecked} fullWidth variant="outlined" color="primary" onClick={this.props.openDialogStartTime}>
                            {this.props.selectedStartTime}
                            </Button>
                        </Grid>
                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography style={{ color: 'gray' }} variant="caption" display="block" gutterBottom>
                            ------
                            </Typography>
                        </Grid>
                        <Grid item xs={5} style={{ paddingLeft: 5}}>
                            <Button disabled={!this.props.switchChecked} fullWidth variant="outlined" color="primary" onClick={this.props.openDialogEndTime}>
                            {this.props.selectedEndTime}
                            </Button>
                        </Grid>
                        
                    </Grid>
                    
            </Grid>
        )
  }
}

DayPicker.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DayPicker);