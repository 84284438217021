import React, { Component } from 'react';
import CustomizedStationTable from '../components/CustomizedStationTable';
import firebase from "firebase";
import { directoryMode } from '../firebase/config';
import app from "../firebase/base";

class Stations extends Component {

    state = {
      stationData: [],
      isMobile: false,
      allData:[],
      filtertxt: ''
    }

    componentDidMount(){

      if(directoryMode[0].mode){
        let ref = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('station');

        ref.on('value', snapshot => {
          const stationData = [];
  
          snapshot.forEach(childSnapshot => {
            const item = childSnapshot.val();
            item.key = childSnapshot.key;
  
            //if(item.zone === 'default')
            //{
              stationData.push({ 
                id:item.key, 
                name: item.name, 
                created: item.created, 
                updated: item.updated, 
                campaign: item.campaign ? item.campaign : null,
                campaign_ads: item.campaign_ads ? item.campaign_ads : null,
                connected: item.connected,
                zone: item.zone,
                sub_zone: item.sub_zone,
                used: item.used,
                app_version: item.app_version ? item.app_version : "1.0.0",
                is_playing: item.is_playing ? true : false,
                push_date: item.push_date ? item.push_date : "0000000000",
                sim_serial: item.sim_serial ? item.sim_serial : "0",
                total_file_in_app: item.total_file_in_app ? item.total_file_in_app : "null",
              });
            //}
          
          });
  
          var filterData = stationData.filter(item => item.name ? item.name.toLowerCase().includes(this.state.filtertxt):"" );
  
          const windowSize = window.innerWidth;
          if(windowSize < 800){
            this.setState({ isMobile: true, stationData: filterData, allData: stationData })
          } else {
            this.setState({ isMobile: false, stationData: filterData, allData: stationData })
          }
  
        });
      }
      else{
        let ref = app.database().ref('station');

        ref.on('value', snapshot => {
          const stationData = [];
  
          if(firebase.auth().currentUser.email == "taysockchoo@apmasia.com.sg" 
          || firebase.auth().currentUser.email == "kymlow@apmasia.com.sg"
          || firebase.auth().currentUser.email == "zhouquan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "yongpohyi@apmasia.com.sg"
          || firebase.auth().currentUser.email == "nurulkhairiah@apmasia.com.sg"
          || firebase.auth().currentUser.email == "cloudzha@apmasia.com.sg"
          || firebase.auth().currentUser.email == "joanyeo@apmasia.com.sg"
          || firebase.auth().currentUser.email == "kartiniarbangi@apmasia.com.sg"
          || firebase.auth().currentUser.email == "edwardtan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "ngyongquan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "joycelynpang@apmasia.com.sg"
          || firebase.auth().currentUser.email == "hoongkaixuan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "faizalbinnorman@apmasia.com.sg"
          || firebase.auth().currentUser.email == "jeremysim@apmasia.com.sg"
          ){

            snapshot.forEach(childSnapshot => {
              const item = childSnapshot.val();
              item.key = childSnapshot.key;
    
              if(item.key === '-M1f79nU5ZbYYZVjc86D' 
              || item.key === '-MNXZ1ATJFRg82UeLgF5' || item.key === '-MNXWcVETq9_W0XozRCC' 
              || item.key === '-MNXRFTGXZwi3PE7BItY' || item.key === '-MNXP97XrPWODjcOziZ5' 
              || item.key === '-MreH_qysYNFWkBRG2Eh' || item.key === '-MreH03TpJ4rHHa0EBk2' 
                || item.key === '-MraPkE2XyXNIelG1MUx' || item.key === '-MraPXhPFAZfkmoKSOKY' )
              {
                stationData.push({ 
                  id:item.key, 
                  name: item.name, 
                  created: item.created, 
                  updated: item.updated, 
                  campaign: item.campaign ? item.campaign : null,
                  campaign_ads: item.campaign_ads ? item.campaign_ads : null,
                  connected: item.connected,
                  zone: item.zone,
                  sub_zone: item.sub_zone,
                  used: item.used,
                  app_version: item.app_version ? item.app_version : "1.0.0",
                  is_playing: item.is_playing ? true : false,
                  push_date: item.push_date ? item.push_date : "0000000000",
                  sim_serial: item.sim_serial ? item.sim_serial : "0",
                  total_file_in_app: item.total_file_in_app ? item.total_file_in_app : "null",
                });
              }
            
            });
            
          }
          else
          {
            snapshot.forEach(childSnapshot => {
              const item = childSnapshot.val();
              item.key = childSnapshot.key;
    
                stationData.push({ 
                  id:item.key, 
                  name: item.name, 
                  created: item.created, 
                  updated: item.updated, 
                  campaign: item.campaign ? item.campaign : null,
                  campaign_ads: item.campaign_ads ? item.campaign_ads : null,
                  connected: item.connected,
                  zone: item.zone,
                  sub_zone: item.sub_zone,
                  used: item.used,
                  app_version: item.app_version ? item.app_version : "1.0.0",
                  is_playing: item.is_playing ? true : false,
                  push_date: item.push_date ? item.push_date : "0000000000",
                  sim_serial: item.sim_serial ? item.sim_serial : "0",
                  total_file_in_app: item.total_file_in_app ? item.total_file_in_app : "null",
                });
              
            
            });
          }
          
  
          var filterData = stationData.filter(item => item.name ? item.name.toLowerCase().includes(this.state.filtertxt):"" );
  
          const windowSize = window.innerWidth;
          if(windowSize < 800){
            this.setState({ isMobile: true, stationData: filterData, allData: stationData })
          } else {
            this.setState({ isMobile: false, stationData: filterData, allData: stationData })
          }
  
        });
      }
      

      window.addEventListener("resize", this.handleResize );
    }

    handleResize  = () => {
      const windowSize = window.innerWidth;
      if(windowSize < 800){
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    }

    search (e) {
      var filterData = this.state.allData.filter(item => item.name ? item.name.toLowerCase().includes(e.target.value.toLowerCase()): "");
  
      this.setState({ stationData: filterData, filtertxt:e.target.value.toLowerCase()  });
    }

    searchReset(){
      var filterData = this.state.allData.filter(item => item.name ? item.name.toLowerCase().includes(this.state.filtertxt):"");
  
      this.setState({ stationData: filterData, filtertxt:""  });
    }

    render() {
      
      return (
          <div style={{ padding: 0 }}>
           <CustomizedStationTable 
              rows={this.state.isMobile ? [
                { id: 'name', numeric: false, disablePadding: true, label: 'Station Name', sortable: true },
                { id: 'connected', numeric: false, disablePadding: false, label: 'Status', sortable: true },
                { id: 'app_version', numeric: false, disablePadding: false, label: 'App Version', sortable: true }
              ] : [
                { id: 'name', numeric: false, disablePadding: true, label: 'Station Name', sortable: true },
                { id: 'connected', numeric: false, disablePadding: false, label: 'Status', sortable: true },
                { id: 'is_playing', numeric: false, disablePadding: false, label: 'Play', sortable: true },
                { id: 'app_version', numeric: false, disablePadding: false, label: 'App Version', sortable: true },
                { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
              ]} 
              data={this.state.stationData}
              orderBy={'created'}
              movefile={false}
              dataName='station'
              isMobile={this.state.isMobile}
              search={(e) => {this.search(e)}}
              searchReset = {() => {this.searchReset()}}
              />
          </div>
      );
    }
}
  
export default Stations;