import React, { Component } from 'react';
import CustomizedCampaignTable from '../components/CustomizedCampaignTable';
import firebase from "firebase";
import { directoryMode } from '../firebase/config';
import app from "../firebase/base";

class Campaigns extends Component {

    state = {
      campaignData: [],
      isMobile: false,
      allData:[],
      filtertxt: ''
    }

    componentWillMount(){

      if(directoryMode[0].mode){
        let ref = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('campaign_tc');

        ref.on('value', snapshot => {
          const campaignData = [];
          
  
          snapshot.forEach(childSnapshot => {
            const item = childSnapshot.val();
            item.key = childSnapshot.key;
  
            campaignData.push({ id:item.key, change: item.change ,name: item.name, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name, text: item.text });
            
          });
  
          var filterData = campaignData.filter(item => item.name.toLowerCase().includes(this.state.filtertxt));
  
          this.setState({ campaignData: filterData, allData: campaignData });
        });
      }
      else{

        if(firebase.auth().currentUser.email == "taysockchoo@apmasia.com.sg" 
          || firebase.auth().currentUser.email == "kymlow@apmasia.com.sg"
          || firebase.auth().currentUser.email == "zhouquan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "yongpohyi@apmasia.com.sg"
          || firebase.auth().currentUser.email == "nurulkhairiah@apmasia.com.sg"
          || firebase.auth().currentUser.email == "cloudzha@apmasia.com.sg"
          || firebase.auth().currentUser.email == "joanyeo@apmasia.com.sg"
          || firebase.auth().currentUser.email == "kartiniarbangi@apmasia.com.sg"
          || firebase.auth().currentUser.email == "edwardtan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "ngyongquan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "joycelynpang@apmasia.com.sg"
          || firebase.auth().currentUser.email == "hoongkaixuan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "faizalbinnorman@apmasia.com.sg"
          || firebase.auth().currentUser.email == "jeremysim@apmasia.com.sg"
          ){

            

            let refstation = app.database().ref('station');

            const stationData = [];
            const campaign_station = [];

            refstation.on('value', snapshot => {
              snapshot.forEach(childSnapshot => {
                const item = childSnapshot.val();
                item.key = childSnapshot.key;
      
                if(item.key === '-M1f79nU5ZbYYZVjc86D' 
                || item.key === '-MNXZ1ATJFRg82UeLgF5' || item.key === '-MNXWcVETq9_W0XozRCC' 
                || item.key === '-MNXRFTGXZwi3PE7BItY' || item.key === '-MNXP97XrPWODjcOziZ5' 
                || item.key === '-MreH_qysYNFWkBRG2Eh' || item.key === '-MreH03TpJ4rHHa0EBk2' 
                || item.key === '-MraPkE2XyXNIelG1MUx' || item.key === '-MraPXhPFAZfkmoKSOKY' )
                {
                  stationData.push({ 
                    id:item.key, 
                    name: item.name, 
                    created: item.created, 
                    updated: item.updated, 
                    campaign: item.campaign ? item.campaign : null,
                    campaign_ads: item.campaign_ads ? item.campaign_ads : null,
                    connected: item.connected,
                    zone: item.zone,
                    sub_zone: item.sub_zone,
                    used: item.used,
                    app_version: item.app_version ? item.app_version : "1.0.0",
                    is_playing: item.is_playing ? true : false,
                    push_date: item.push_date ? item.push_date : "0000000000",
                    sim_serial: item.sim_serial ? item.sim_serial : "0",
                    total_file_in_app: item.total_file_in_app ? item.total_file_in_app : "null",
                  });

                  if (campaign_station.indexOf(item.campaign)==-1)
                  {
                    campaign_station.push(item.campaign)
                  }
                }
              
              });

              let ref = app.database().ref('campaign_tc');

              ref.on('value', snapshot => {
                const campaignData = [];
                
                snapshot.forEach(childSnapshot => {
                  const item = childSnapshot.val();
                  item.key = childSnapshot.key;

                  if (campaign_station.indexOf(item.key)!=-1){
                    campaignData.push({ id:item.key, change: item.change ,name: item.name, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name, text: item.text });
                  }
                  
                });
        
                var filterData = campaignData.filter(item => item.name.toLowerCase().includes(this.state.filtertxt));
        
                this.setState({ campaignData: filterData, allData: campaignData });
              });

            });

        }
        else
        {
          let ref = app.database().ref('campaign_tc');

            ref.on('value', snapshot => {
              const campaignData = [];
              
      
              snapshot.forEach(childSnapshot => {
                const item = childSnapshot.val();
                item.key = childSnapshot.key;
      
                campaignData.push({ id:item.key, change: item.change ,name: item.name, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name, text: item.text });
                
              });
      
              var filterData = campaignData.filter(item => item.name.toLowerCase().includes(this.state.filtertxt));
      
              this.setState({ campaignData: filterData, allData: campaignData });
            });
        }
        
      }
      


      window.addEventListener("resize", this.handleResize );
    }

    search (e) {
      var filterData = this.state.allData.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
  
      this.setState({ campaignData: filterData, filtertxt:e.target.value.toLowerCase()  });
    }

    handleResize  = () => {
      const windowSize = window.innerWidth;
      if(windowSize < 800){
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    }

    render() {
      return (
          <div>
           <CustomizedCampaignTable 
              rows={this.state.isMobile ? [
                { id: 'name', numeric: false, disablePadding: true, label: 'Campaign Name', sortable: true },
                { id: 'change', numeric: false, disablePadding: false, label: 'Published', sortable: true },
                
              ] : [
                { id: 'name', numeric: false, disablePadding: true, label: 'Campaign Name', sortable: true },
                { id: 'change', numeric: false, disablePadding: false, label: 'Published', sortable: true },
                { id: 'change', numeric: false, disablePadding: false, label: 'Publish', sortable: false },
                { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
              ]} 
              data={this.state.campaignData}
              orderBy={'created'}
              movefile={false}
              ads={false}
              dataName='campaign'
              isMobile={this.state.isMobile}
              search={(e) => {this.search(e)}}
              />
          </div>
      );
    }
}
  
export default Campaigns;