import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

//authentication
import SignIn from "./screens/authentication/SignIn";
import SignUp from "./screens/authentication/SignUp";
import ForgotPassword from "./screens/authentication/ForgotPassword";
import app from "./firebase/base";
import { directoryMode } from './firebase/config';

//screens
import Dashboard from "./screens/Dashboard";
import Stations from "./screens/Stations";
import Contents from "./screens/Contents";
import Templates from "./screens/Templates";
import Campaigns from "./screens/Campaigns";
import Campaigns_ads from "./screens/Campaigns_ads";
import Help from "./screens/Help";
import Admin from "./screens/Admin";
import Error from "./screens/Error";
import Navigation from "./screens/Navigation";

import IdleTimer from 'react-idle-timer'

import firebase from "firebase";
import moment from "moment";
import Typography from '@material-ui/core/Typography';
import logo from './assets/logo.png';

//dialog
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

const multiDataSet = [
  {
      columns: ["Name", "Salary", "Sex"],
      data: [
          ["Johnson", 30000, "Male"],
          ["Monika", 355000, "Female"],
          ["Konstantina", 20000, "Female"],
          ["John", 250000, "Male"],
          ["Josef", 450500, "Male"],
      ]
  },
  {
      xSteps: 1, // Will start putting cell with 1 empty cell on left most
      ySteps: 5, //will put space of 5 rows,
      columns: ["Name", "Department"],
      data: [
          ["Johnson", "Finance"],
          ["Monika", "IT"],
          ["Konstantina", "IT Billing"],
          ["John", "HR"],
          ["Josef", "Testing"],
      ]
  }
];


class App extends Component {

  constructor(props) {
    super(props)

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)

  }

  state = {
    timeout:1000 * 15 * 60,
    isTimedOut: false,
    loading: true, authenticated: false, user: null,
    openSecondDialog: false,
    count_down_number: 10,
    user_id: null,
    user_email: null,
    isDirectory: null,
    enter: false
  };

  componentWillMount() {

    directoryMode[0].mode = false;

    app.auth().onAuthStateChanged(user => {

      
      if (user) {

        this.setState({
          authenticated: true,
          currentUser: user,
          loading: false,
          user_id: user.uid,
          user_email: user.email,
          enter: false
        });
        
      } else {

        if(this.state.user_id){
          var postData = {
            email: this.state.user_email,
            uid: this.state.user_id,
            session: null
          };
      
          var updates = {};
          updates['/user/' + this.state.user_id] = postData;
        
          //firebase.database().ref().update(updates);
        }

        this.setState({
          authenticated: false,
          currentUser: null,
          loading: false
        });
      }
    });

  }

  _onAction(e) {
    //console.log('user did something', e)

    if(this.state.authenticated){
      var postData = {
        email: this.state.user_email,
        uid: this.state.user_id,
        session: firebase.database.ServerValue.TIMESTAMP
      };
  
      var updates = {};
      updates['/user/' + this.state.user_id] = postData;
    
      //firebase.database().ref().update(updates);
    }
    
  }
 
  _onActive(e) {
    //console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
  _onIdle(e) {
    console.log('user is idle', e)
    console.log('last active', this.idleTimer.getLastActiveTime())

    const isTimedOut = this.state.isTimedOut
      if (!isTimedOut) {
        this.setState({isTimedOut: true})

        this.setState({ openSecondDialog: true });

        this.timeout = setInterval(() => {

          if(this.state.count_down_number == 1){
            clearInterval(this.timeout)
            this.setState({ openSecondDialog: false,  isTimedOut: false, count_down_number: 10});

            app.auth().signOut();
          } else {
            this.setState({ count_down_number: this.state.count_down_number - 1 });
          }
          
        }, 1000);
        
      } 
  }

  renderRoute(){


    return(
      <Switch>
        <Route path="/" component={Contents} exact />
        <Route path="/stations" component={Stations} />
        <Route path="/campaigns" component={Campaigns} />
        <Route path="/help" component={Help} />
        {directoryMode[0].mode ? <Route path="/banner" component={Campaigns_ads} />:null}
        <Route component={Error} />
      </Switch>
    )
  }

  handleSecondClose = () => {
    clearInterval(this.timeout)
    this.setState({ openSecondDialog: false,  isTimedOut: false, count_down_number: 10});
  }

  handleContinue = () => {
    clearInterval(this.timeout)
    this.setState({ openSecondDialog: false,  isTimedOut: false, count_down_number: 10});
  }

  handleLogout = () => {
    clearInterval(this.timeout)
    this.setState({ openSecondDialog: false,  isTimedOut: false, count_down_number: 10});

    app.auth().signOut();
  }

  onPressedInteractive = () => {
    localStorage.setItem('directoryMode', 'true')
    directoryMode[0].mode = true;
    this.setState({ enter: true })
    
  }

  onPressedDigital = () => {
    localStorage.setItem('directoryMode', 'false')
    directoryMode[0].mode = false;
    this.setState({ enter: true })
    
  }

  onPressedLogout = () => {
    app.auth().signOut();
  }

  render(){
    
    const { authenticated, loading } = this.state;

    if (loading) {
      return <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'  }}><CircularProgress color="primary" /></div>;
    }

    
    if(authenticated)
    {

      if(directoryMode[0].mode != null){

        return (
          <BrowserRouter>
            <div>
              <Dialog
                  onClose={this.handleSecondClose}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.openSecondDialog}
                  maxWidth='xl'
              >
                <DialogTitle id="customized-dialog-title" onClose={this.handleSecondClose}>
                    Your session is about to expire.
                </DialogTitle>
                <DialogContent>
                  You will logged out in {this.state.count_down_number} seconds
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleContinue} color="primary">
                  Continue Session
                  </Button>
                  <Button onClick={this.handleLogout} color="default">
                  Log Out
                  </Button>
                </DialogActions>
              </Dialog>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={this.state.timeout} />
              <Navigation components={this.renderRoute()}/>
            </div>
          </BrowserRouter>
        );
      
      }
      else
      {
        return(<div style={{  display:'flex', marginTop: 100, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Button style={{ width: 200 }} variant="outlined" color="default" onClick={()=> this.onPressedLogout()}>
              Logout
            </Button>
          </div>)
      }
        
      
      
    } else {
      
      return (
        <BrowserRouter>
          <div>
            <Switch>
              <Route path="/" component={SignIn} exact/>
              <Route path="/signup" component={SignUp} />
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route component={Error} />
            </Switch>
          </div>
        </BrowserRouter>
      );
      
    }

  }
}

export default App;
