import React, { Component } from 'react';
import CustomizedUserTable from '../components/CustomizedUserTable';
import firebase from "firebase"

class Admin extends Component {

    state = {
      userData: []
    }

    componentWillMount(){
      let ref = firebase.database().ref('user');

      ref.on('value', snapshot => {
        const userData = [];

        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;

          userData.push({ 
            id:item.key, 
            name: item.name, 
            created: item.created, 
            updated: item.updated, 
            email: item.email,
            group: item.group,
            phone: item.phone,
            role: item.role,
            suspend: item.suspend
          });
          
        });

        this.setState({ userData });
      });
    }

    render() {
      return (
          <div>
           <CustomizedUserTable 
              rows={[
                { id: 'name', numeric: false, disablePadding: true, label: 'Full Name', sortable: true },
                { id: 'role', numeric: false, disablePadding: false, label: 'Role', sortable: true },
              ]} 
              data={this.state.userData}
              orderBy={'created'}
              movefile={false}
              dataName='user'/>
          </div>
      );
    }
}
  
export default Admin;