import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PreviewIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import firebase from "firebase";
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Cached';
import EjectIcon from '@material-ui/icons/Eject';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingRemote from '@material-ui/icons/SettingsRemote';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import CustomizedCampaignList from './CustomizedCampaignList'
import CheckedCircle from '@material-ui/icons/CheckCircle';
import HighlightOff from '@material-ui/icons/HighlightOff';
import CustomizedStationZoneTable from '../components/CustomizedStationZoneTable'
import { SubdirectoryArrowLeft } from '@material-ui/icons'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import InfoIcon from '@material-ui/icons/Info';
import Slider from '@material-ui/core/Slider';
import { OTSession, OTStreams, OTSubscriber } from 'opentok-react';
import { directoryMode } from '../firebase/config';
import app from "../firebase/base";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows } = this.props;
    
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false }
              >
                <Tooltip
                  title="Sort"
                  disableTouchListener={row.sortable ? false : true }
                  disableFocusListener={row.sortable ? false : true }
                  disableHoverListener={row.sortable ? false : true }
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={(orderBy === row.id && row.sortable) ? true : false }
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});


let EnhancedTableToolbar = props => {
  const { numSelected, classes, onDeleteAllClick, movefile, onAddClick, onMoveAllClick } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            
          </Typography>
        )}
      </div>
      
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          movefile ? <div style={{ display: 'flex', alignItems: 'center' }}>

            <Tooltip title="Move">
                <IconButton aria-label="Move" onClick={onMoveAllClick}>
                <SubdirectoryArrowLeft />
                </IconButton>
            </Tooltip>
            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> : null }
            </div> : 
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Move">
                <IconButton aria-label="Move" onClick={onMoveAllClick}>
                <SubdirectoryArrowLeft />
                </IconButton>
            </Tooltip>
            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> : null}
            </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
          {firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Add Station" onClick={onAddClick}>
              <IconButton aria-label="Add">
              <AddIcon />
              </IconButton>
        </Tooltip> : <div></div> }
          </div>
        )}
      </div>
      
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  actionButtons: {
    display: 'flex'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: 10,
    flex: 1,
  },
  tab: {
    borderLeft: '1px solid #e8e8e8',
  },
});

function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class EnhancedTable extends React.Component {

  constructor(props) {
    super(props);

    var newWidth = (window.innerHeight - 100) * 1080;
    this.subscriberProperties = {
      preferredFrameRate: 15,
      showControls: false,
      height: window.innerHeight - 100,
      width: newWidth / 1920,
      subscribeToAudio: false,
    };

    this.subscriberEventHandlers = {
      videoDisabled: event => {
        console.log('Subscriber video disabled!');
      },
      videoEnabled: event => {
        console.log('Subscriber video enabled!');
      }
    };
    
    window.addEventListener("beforeunload", (ev) => 
    {  

      if(this.state.selectedId != ''){

        if(directoryMode[0].mode){
          app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('/push_station/' + this.state.selectedId +'/remote').transaction(function (current_value) {
            return false;
          });
        }
        else{
          app.database().ref('/push_station/' + this.state.selectedId +'/remote').transaction(function (current_value) {
            return false;
          });
        }
        
      }
        ev.preventDefault();
        
    });
  }

  state = {
    order: 'desc',
    orderBy: this.props.orderBy,
    selected: [],
    selectedId: '',
    page: 0,
    rowsPerPage: 20,
    openDialog: false,
    dialogTitle: 'No Title',
    folderId: '',
    folderName: '',
    dialogOption: '',
    selectedFileName: '',
    dialogFullscreen: false,
    value: 0,
    stationName: '',
    openSecondDialog: false,
    openThirdDialog: false,
    campaignData: [],
    campaignAdsData: [],
    selectedCampaign: null,
    selectedAdsCampaign: null,
    selectedEditStation: null,
    zoneCollection:[],
    selectedZone: 'default',
    selectedTableCell: '',
    subZoneData: [],
    selectedSubZone: 'default',
    pushStationData: [],
  };

  MouseDown = event => {

    var newWidth = ((window.innerHeight - 100) * 1080) / 1920;

    let touch_x = event.nativeEvent.offsetX;
    let touch_y = event.nativeEvent.offsetY;

    let page_touch_x = touch_x * 1080 / newWidth
    let page_touch_y = touch_y * 1920 / (window.innerHeight - 100)

    if(touch_x < newWidth && touch_x > 0 && touch_y > 0 && touch_y < (window.innerHeight - 100)){
      
      if(directoryMode[0].mode){
        app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station/' + this.state.selectedId ).update({ action : "mouse" })

        app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('/push_station/' + this.state.selectedId + '/touch_x').transaction(function (current_value) {
          return page_touch_x.toFixed(2);
        });
    
        app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('/push_station/' + this.state.selectedId + '/touch_y').transaction(function (current_value) {
          return page_touch_y.toFixed(2);
        });
      }
      else
      {
        app.database().ref('push_station/' + this.state.selectedId ).update({ action : "mouse" })

        app.database().ref('/push_station/' + this.state.selectedId + '/touch_x').transaction(function (current_value) {
          return page_touch_x.toFixed(2);
        });
    
        app.database().ref('/push_station/' + this.state.selectedId + '/touch_y').transaction(function (current_value) {
          return page_touch_y.toFixed(2);
        });
      }
     
    }
    
    
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.data.map(n => n.id) })
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleToggle = (event, id) => { 

    if(id === this.state.selectedTableCell){
      this.setState({ selectedTableCell: ''})
    } else {
      this.setState({ selectedTableCell: id})
    }
    
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  onEditClick = (id) => {

    const currentStationIndex = this.props.data.map((val)=>{ return val.id }).indexOf(id)

    const currentCampaignIndex = this.state.campaignData.map((val)=> { return val.id }).indexOf(this.props.data[currentStationIndex].campaign)

    const currentAdsCampaignIndex = this.state.campaignAdsData.map((val)=> { return val.id }).indexOf(this.props.data[currentStationIndex].campaign_ads)

    const subZoneData = []

    var selectedZone = this.props.data[currentStationIndex].zone ? this.props.data[currentStationIndex].zone : "default";



    if(directoryMode[0].mode){

      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('station_zone/' + selectedZone + "/sub_zone").on('value', stationZoneSnapshot => { 

        stationZoneSnapshot.forEach(stationZoneChildSnapshot => {
          const item = stationZoneChildSnapshot.val();
          item.key = stationZoneChildSnapshot.key;
          subZoneData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
        })
  
        this.setState({
          openDialog: true,
          dialogOption: 'edit',
          dialogTitle: "Edit Station",
          dialogFullscreen: false,
          selectedEditStation: this.props.data[currentStationIndex],
          stationName: this.props.data[currentStationIndex].name,
          selectedId: id,
          selectedCampaign : this.state.campaignData[currentCampaignIndex],
          selectedAdsCampaign : currentAdsCampaignIndex == -1 ? 'none' : this.state.campaignAdsData[currentAdsCampaignIndex],
          selectedZone: selectedZone ? selectedZone : "default",
          selectedSubZone: this.props.data[currentStationIndex].sub_zone ? this.props.data[currentStationIndex].sub_zone : "default",
          subZoneData
        });
  
      })

    }
    else
    {
      app.database().ref('station_zone/' + selectedZone + "/sub_zone").on('value', stationZoneSnapshot => { 

        stationZoneSnapshot.forEach(stationZoneChildSnapshot => {
          const item = stationZoneChildSnapshot.val();
          item.key = stationZoneChildSnapshot.key;
          subZoneData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
        })
  
        this.setState({
          openDialog: true,
          dialogOption: 'edit',
          dialogTitle: "Edit Station",
          dialogFullscreen: false,
          selectedEditStation: this.props.data[currentStationIndex],
          stationName: this.props.data[currentStationIndex].name,
          selectedId: id,
          selectedCampaign : this.state.campaignData[currentCampaignIndex],
          selectedAdsCampaign : this.state.campaignAdsData[currentAdsCampaignIndex],
          selectedZone: selectedZone ? selectedZone : "default",
          selectedSubZone: this.props.data[currentStationIndex].sub_zone ? this.props.data[currentStationIndex].sub_zone : "default",
          subZoneData
        });
  
      })
    }
    
  }

  onInfoClick = (id) => {
    this.setState({
        openDialog: true,
        dialogTitle: "Info",
        selectedId: id,
        dialogOption: 'info',
    });
  }

  onDeleteClick = (id) => {
    this.setState({
        openDialog: true,
        dialogTitle: "Remove Station",
        selectedId: id,
        dialogOption: 'delete',
        dialogFullscreen: false
    });
  }

  onPreviewClick = (id) => {

  }

  onDeleteAllClick = () => {
    this.setState({
        openDialog: true,
        dialogOption: 'deleteAll',
        dialogTitle: "Remove Station",
        dialogFullscreen: false
    });
  }

  handleClose = () => {

    if(this.state.selectedId != ''){

      if(directoryMode[0].mode){
        app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('/push_station/' + this.state.selectedId +'/remote').transaction(function (current_value) {
          return false;
        });
      }
      else
      {
        app.database().ref('/push_station/' + this.state.selectedId +'/remote').transaction(function (current_value) {
          return false;
        });
      }
      
    }

    this.setState({ openDialog: false, selectedCampaign: null });
  };

  handleDialogClose = () => {
    this.setState({ openSecondDialog: false  });
   
  }

  handleDialogThirdClose = () => {
    this.setState({ openThirdDialog: false  });
   
  }

  handleSelectionChange = event => {

    const subZoneData = []

    if(directoryMode[0].mode){

      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('station_zone/' + event.target.value + "/sub_zone").on('value', mediaFolderSnapshot => { 

        mediaFolderSnapshot.forEach(mediaFolderChildSnapshot => {
          const item = mediaFolderChildSnapshot.val();
          item.key = mediaFolderChildSnapshot.key;
          subZoneData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
        })
  
        this.setState({ selectedZone: event.target.value, subZoneData, selectedSubZone: 'default' });
      })

    }
    else
    {
      app.database().ref('station_zone/' + event.target.value + "/sub_zone").on('value', mediaFolderSnapshot => { 

        mediaFolderSnapshot.forEach(mediaFolderChildSnapshot => {
          const item = mediaFolderChildSnapshot.val();
          item.key = mediaFolderChildSnapshot.key;
          subZoneData.push({ id:item.key, created: item.created, total: item.total, name: item.name })
        })
  
        this.setState({ selectedZone: event.target.value, subZoneData, selectedSubZone: 'default' });
      })
    }
    
  };

  CustomizedSubZoneData = event => {

    this.setState({ selectedSubZone: event.target.value });
  };

  handleRemoveFile = () => {

    if(directoryMode[0].mode){
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref(this.props.dataName).child(this.state.selectedId).remove()
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref("push_station").child(this.state.selectedId).remove()
    }
    else
    {
      app.database().ref(this.props.dataName).child(this.state.selectedId).remove()
      app.database().ref("push_station").child(this.state.selectedId).remove()
    }
    

    this.setState({
      openDialog: false,
      selectedId: '',
      selected: []
    });
    
  }

  handleRemoveAllFile = () => {

    this.state.selected.forEach(items => {

      if(directoryMode[0].mode){
        app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref(this.props.dataName).child(items).remove()
        app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref("push_station").child(items).remove()
      }
      else
      {
        app.database().ref(this.props.dataName).child(items).remove()
        app.database().ref("push_station").child(items).remove()
      }
        
    })

    this.setState({
      openDialog: false,
      selected: []
    });
  }

  renderDialog = () => {

    if(this.state.dialogOption === 'delete'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these station?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'deleteAll'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these station?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveAllFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    } 
    
    else if(this.state.dialogOption === 'restart'){
      return(
          <div>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Are you sure you want to restart these station?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleRestartStation} color="default">
                  Yes
                  </Button>
                  <Button color="primary" onClick={this.handleClose}>
                  No
                  </Button>
              </DialogActions>
          </div>
      )
  } 

    else if(this.state.dialogOption === 'reset'){
      return(
          <div>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Are you sure you want to reset this station?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleResetStation} color="default">
                  Yes
                  </Button>
                  <Button color="primary" onClick={this.handleClose}>
                  No
                  </Button>
              </DialogActions>
          </div>
      )
    } 

    else if(this.state.dialogOption === 'clear'){
      return(
          <div>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Are you sure you want to push and clear data on this station?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleClearStation} color="default">
                  Yes
                  </Button>
                  <Button color="primary" onClick={this.handleClose}>
                  No
                  </Button>
              </DialogActions>
          </div>
      )
    } 

    else if(this.state.dialogOption === 'addStation'){
      return(
          <div style={{backgroundColor: '#f6f6f6'}}>
              <DialogContent >
                  <TextField
                    fullWidth
                    margin="dense"
                    id="standard-name"
                    label="Station Name"
                    value={this.state.stationName}
                    onChange={this.handleStationNameChange}
                  />
                  <Button style={{marginTop: 10}} fullWidth variant="outlined" color="primary" onClick={this.handleSelectCampaign}>
                    {this.state.selectedCampaign ? this.state.selectedCampaign.name : 'Select Campaign'}
                  </Button>
                  {directoryMode[0].mode == true ? <Button style={{marginTop: 10}} fullWidth variant="outlined" color="primary" onClick={this.handleSelectThirdCampaign}>
                    {this.state.selectedAdsCampaign ? this.state.selectedAdsCampaign.name : 'Select Banner'}
                  </Button> : null}
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleAddStation} color="default">
                  Yes
                  </Button>
                  <Button color="primary" onClick={this.handleClose}>
                  No
                  </Button>
              </DialogActions>
          </div>
      )
    } 

    else if(this.state.dialogOption === 'info'){
      var id =  this.props.data.map(val=> { return val.id }).indexOf(this.state.selectedId);

      let selectedCampaign = 'none'
      let totalCampaignContent = 'null'
      const currentCampaignIndex = this.state.campaignData.map(val => { return val.id}).indexOf(this.props.data[id].campaign)
      if(currentCampaignIndex !== -1){
        selectedCampaign = this.state.campaignData[currentCampaignIndex].name
        totalCampaignContent = this.state.campaignData[currentCampaignIndex].content[0].length
      }
      
      const currentZoneIndex = this.state.zoneCollection.map(val => { return val.id}).indexOf(this.props.data[id].zone)

      let selectedzone = 'none'
      if(currentZoneIndex !== -1){
        selectedzone = this.state.zoneCollection[currentZoneIndex].name;
      }

      return(
          <div>
              <DialogContent>
              <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right"></TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Id
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {this.state.selectedId}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Campaign
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {selectedCampaign}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      Created by
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.props.data[id].created)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      Updated by
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.props.data[id].updated)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      Pushed by
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(this.props.data[id].push_date)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      Zone
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {selectedzone}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      SIM Serial
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {this.props.data[id].sim_serial}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      Total of file (App)
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {this.props.data[id].total_file_in_app}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                      Total of file (campaign)
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {totalCampaignContent}
                      </TableCell>
                    </TableRow>
                    
                  </TableBody>
                </Table>
              </DialogContent>
          </div>
      )
    }

    else if(this.state.dialogOption === 'edit'){

      return(
          <div style={{backgroundColor: '#f6f6f6' }}>
              <DialogContent >
                  <TextField
                    fullWidth
                    margin="dense"
                    id="standard-name"
                    label="Station Name"
                    value={this.state.stationName}
                    onChange={this.handleStationNameChange}
                  />
                  <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off">
                    <InputLabel
                    ref={ref => {
                        this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-age-simple"
                    >
                    Zone
                    </InputLabel>
                    <Select
                    value={this.state.selectedZone}
                    onChange={this.handleSelectionChange}
                    input={
                        <OutlinedInput
                        labelWidth={100}
                        name="zone"
                        id="outlined-age-simple"
                        />
                    }
                    >
                    
                    <MenuItem value="default">
                        <em>Default</em>
                    </MenuItem>
                    {this.state.zoneCollection.map(n => {
                        return(
                        <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                        )
                    })}

                    </Select>
                  </FormControl>
                  <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off" >
                    <InputLabel>
                        SubZone
                    </InputLabel>
                    <Select
                        disabled={this.state.subZoneData.length > 0 ? false : true}
                        value={this.state.selectedSubZone}
                        onChange={this.CustomizedSubZoneData}
                        input={
                        <OutlinedInput
                            labelWidth={100}
                            name="zone"
                            id="outlined-age-simple"
                        />
                        }
                    >
                        <MenuItem value="default">
                        <em>Default</em>
                        </MenuItem>
                        {this.state.subZoneData.map(n => {
                            return(
                                <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                            )
                        })}

                    </Select>
                </FormControl>
                  <Button style={{marginTop: 10}} fullWidth variant="outlined" color="primary" onClick={this.handleSelectCampaign}>
                    {this.state.selectedCampaign ? this.state.selectedCampaign.name : 'Select Campaign'}
                  </Button>
                  {directoryMode[0].mode == true ? <Button style={{marginTop: 10}} fullWidth variant="outlined" color="primary" onClick={this.handleSelectThirdCampaign}>
                    {this.state.selectedAdsCampaign ? this.state.selectedAdsCampaign.name : 'Select Banner'}
                  </Button> : null}
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleEditStation} color="default">
                  Yes
                  </Button>
                  <Button color="primary" onClick={this.handleClose}>
                  No
                  </Button>
              </DialogActions>
          </div>
      )
    } else if(this.state.dialogOption === 'moveAll'){
      return(
          <div>
          <DialogContent style={{ width: 200}}>
              <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off">
                  <InputLabel
                  ref={ref => {
                      this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                  >
                  Zone
                  </InputLabel>
                  <Select
                  value={this.state.selectedZone}
                  onChange={this.handleSelectionChange}
                  input={
                      <OutlinedInput
                      labelWidth={100}
                      name="zone"
                      id="outlined-age-simple"
                      />
                  }
                  >
                  
                  <MenuItem value="default">
                      <em>Default</em>
                  </MenuItem>
                  {this.state.zoneCollection.map(n => {
                      return(
                      <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                      )
                  })}

                  </Select>
              </FormControl>
              <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off" >
                    <InputLabel>
                        SubZone
                    </InputLabel>
                    <Select
                        disabled={this.state.subZoneData.length > 0 ? false : true}
                        value={this.state.selectedSubZone}
                        onChange={this.CustomizedSubZoneData}
                        input={
                        <OutlinedInput
                            labelWidth={100}
                            name="folder"
                            id="outlined-age-simple"
                        />
                        }
                    >
                        <MenuItem value="default">
                        <em>Default</em>
                        </MenuItem>
                        {this.state.subZoneData.map(n => {
                            return(
                                <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                            )
                        })}

                    </Select>
              </FormControl>
          </DialogContent>
          <DialogActions>
              <Button onClick={this.handleMoveAllFile} color="primary">
              Yes
              </Button>
              <Button onClick={this.handleClose} color="primary" autoFocus>
              No
              </Button>
          </DialogActions>
          </div>
      )
    }else if(this.state.dialogOption === 'remoteControl'){
      return(
        <DialogContent style={{ display: 'flex' , justifyContent: 'center', alignItems: 'center' }}>
            <div style={{display: 'flex', width: 'fit-content', justifyContent: 'center', backgroundColor: 'red'}} onClick={this.MouseDown}>
              <OTSession apiKey="46716462" sessionId="2_MX40NjcxNjQ2Mn5-MTU4ODU3MDc5MDU5MH5ieThRRndPOEczWWdGV1BocGdVNnhNL25-fg" token="T1==cGFydG5lcl9pZD00NjcxNjQ2MiZzaWc9Nzc5NzAyMTgxOTI2OWMyMDJlNDRhYTA3OGE1ZDk4YmVhNTkwYjY5MTpzZXNzaW9uX2lkPTJfTVg0ME5qY3hOalEyTW41LU1UVTRPRFUzTURjNU1EVTVNSDVpZVRoUlJuZFBPRWN6V1dkR1YxQm9jR2RWTm5oTkwyNS1mZyZjcmVhdGVfdGltZT0xNTg4NTcxMDAwJm5vbmNlPTAuMDk4NTE0NzUwNzgzOTAzNyZyb2xlPXN1YnNjcmliZXImZXhwaXJlX3RpbWU9MTU5MTE2MzAwMCZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==">
                <OTStreams >
                  <OTSubscriber
                    properties={this.subscriberProperties}
                    eventHandlers={this.subscriberEventHandlers}
                  />
                </OTStreams>
              </OTSession>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Button color="primary"  onClick={() => this.handleAction("home") }>
              Home
              </Button>
              <Button color="primary"  onClick={() => this.handleAction("back") }>
              Back
              </Button>
            </div>
        </DialogContent>
      )
    }
  }

  handleAction = (actionText) => {
    if(directoryMode[0].mode){
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station/' + this.state.selectedId ).update({ action : "button", action_button: actionText })
    }
    else
    {
      app.database().ref('push_station/' + this.state.selectedId ).update({ action : "button", action_button: actionText })
    }
    
  }

  handleMoveAllFile = () => {

    if(this.state.selectedZone !== 'default'){
      this.state.selected.forEach(items => {

        if(this.state.selectedSubZone !== 'default'){

          if(directoryMode[0].mode){
            app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('/station/' + items).update({ zone: this.state.selectedZone, sub_zone: this.state.selectedSubZone });

            app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('/station_zone/' + this.state.selectedZone +"/sub_zone/" + this.state.selectedSubZone).child('total').transaction( total => {
            
              return total + 1;
            })
          }
          else
          {
            app.database().ref().child('/station/' + items).update({ zone: this.state.selectedZone, sub_zone: this.state.selectedSubZone });

            app.database().ref().child('/station_zone/' + this.state.selectedZone +"/sub_zone/" + this.state.selectedSubZone).child('total').transaction( total => {
            
              return total + 1;
            })
          }
          
        } else {

          if(directoryMode[0].mode){
            app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('/station/' + items).update({ zone: this.state.selectedZone });
          }
          else{
            app.database().ref().child('/station/' + items).update({ zone: this.state.selectedZone });
          }
          
        }
  
      })
    }

    this.setState({
        openDialog: false,
        selectedZone: 'default',
        selected: [],
        selectedZone: 'default',
        selectedSubZone: 'default',
    });

  }

  handleSelectCampaign = () => {
    this.setState({ openSecondDialog: true});
  }

  handleSelectThirdCampaign = () => {
    this.setState({ openThirdDialog: true});
  }

  handleStationNameChange = event => {
    this.setState({ stationName: event.target.value });
  };  

  handleAddStation = () => {

    if(directoryMode[0].mode){
      var newPostKey = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('station').push().key;

      var postData = {
        name: this.state.stationName == ''? newPostKey : this.state.stationName,
        created: firebase.database.ServerValue.TIMESTAMP,
        updated: firebase.database.ServerValue.TIMESTAMP,
        campaign: this.state.selectedCampaign ? this.state.selectedCampaign.id : null,
        campaign_ads: this.state.selectedAdsCampaign ? this.state.selectedAdsCampaign.id : null,
        connected: false,
        used: false,
        zone: 'default'
      };

      var updates = {};
      updates['/station/' + newPostKey] = postData;
    
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().update(updates);

      var pushData = {
        push: false,
        message: '',
        brightness: '0F',
        volume: '0'
      };

      var push_updates = {};
      push_updates['/push_station/' + newPostKey] = pushData;

      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().update(push_updates);
      
      this.setState({ openDialog: false, selectedCampaign: null, selectedAdsCampaign:null, stationName: '' });

    }
    else
    {
      var newPostKey = app.database().ref().child('station').push().key;

      var postData = {
        name: this.state.stationName == ''? newPostKey : this.state.stationName,
        created: firebase.database.ServerValue.TIMESTAMP,
        updated: firebase.database.ServerValue.TIMESTAMP,
        campaign: this.state.selectedCampaign ? this.state.selectedCampaign.id : null,
        campaign_ads: this.state.selectedAdsCampaign ? this.state.selectedAdsCampaign.id : null,
        connected: false,
        used: false,
        zone: 'default'
      };

      var updates = {};
      updates['/station/' + newPostKey] = postData;
    
      app.database().ref().update(updates);

      var pushData = {
        push: false,
        message: '',
        brightness: '0F',
        volume: '0'
      };

      var push_updates = {};
      push_updates['/push_station/' + newPostKey] = pushData;

      app.database().ref().update(push_updates);
      
      this.setState({ openDialog: false, selectedCampaign: null, selectedAdsCampaign: null, stationName: '' });
    }
  }

  onMoveAllClick = () => {
    this.setState({
        openDialog: true,
        dialogOption: 'moveAll',
        dialogTitle: "Move Station",
        selectedZone: 'default',
        selectedSubZone: 'default',
        subZoneData: []
    });
  }

  handleEditStation = () => {

    if(this.state.selectedSubZone !== 'default'){

      if(directoryMode[0].mode){
        app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('/station/' + this.state.selectedId).update({ 
          name: this.state.stationName == ''? this.state.selectedEditStation.id : this.state.stationName, 
          zone: this.state.selectedZone, 
          sub_zone: this.state.selectedSubZone, 
          updated: firebase.database.ServerValue.TIMESTAMP,
          campaign: this.state.selectedCampaign ? this.state.selectedCampaign.id : null,
          campaign_ads: this.state.selectedAdsCampaign ? this.state.selectedAdsCampaign.id : null,
          connected: this.state.selectedEditStation.connected,
          used: this.state.selectedEditStation.used });
      }
      else
      {
        app.database().ref().child('/station/' + this.state.selectedId).update({ 
          name: this.state.stationName == ''? this.state.selectedEditStation.id : this.state.stationName, 
          zone: this.state.selectedZone, 
          sub_zone: this.state.selectedSubZone, 
          updated: firebase.database.ServerValue.TIMESTAMP,
          campaign: this.state.selectedCampaign ? this.state.selectedCampaign.id : null,
          campaign_ads: this.state.selectedAdsCampaign ? this.state.selectedAdsCampaign.id : null,
          connected: this.state.selectedEditStation.connected,
          used: this.state.selectedEditStation.used });
      }
      

      
    } else {

      if(directoryMode[0].mode){
        app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('/station/' + this.state.selectedId).update({ 
          name: this.state.stationName == ''? this.state.selectedEditStation.id : this.state.stationName, 
          zone: this.state.selectedZone, 
          sub_zone: this.state.selectedSubZone, 
          updated: firebase.database.ServerValue.TIMESTAMP,
          campaign: this.state.selectedCampaign ? this.state.selectedCampaign.id : null,
          campaign_ads: this.state.selectedAdsCampaign ? this.state.selectedAdsCampaign.id : null,
          connected: this.state.selectedEditStation.connected,
          used: this.state.selectedEditStation.used });
      }
      else
      {
        app.database().ref().child('/station/' + this.state.selectedId).update({ 
          name: this.state.stationName == ''? this.state.selectedEditStation.id : this.state.stationName, 
          zone: this.state.selectedZone, 
          sub_zone: this.state.selectedSubZone, 
          updated: firebase.database.ServerValue.TIMESTAMP,
          campaign: this.state.selectedCampaign ? this.state.selectedCampaign.id : null,
          campaign_ads: this.state.selectedAdsCampaign ? this.state.selectedAdsCampaign.id : null,
          connected: this.state.selectedEditStation.connected,
          used: this.state.selectedEditStation.used });
      }
      
    }

    if(this.state.selectedZone !== 'default'){

      if(directoryMode[0].mode){
        app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('/station_zone/' + this.state.selectedZone).child('total').transaction( total => {
        
          return total + 1;
          })
      }
      else
      {
        app.database().ref().child('/station_zone/' + this.state.selectedZone).child('total').transaction( total => {
        
          return total + 1;
          })
      }
        
    }

    this.setState({
      openDialog: false,
      selectedCampaign: null,
      selectedAdsCampaign: null,
      selectedZone: 'default',
      selectedSubZone: 'default',
      stationName: '',
      selectedNoted: '',
    });

  }

  handleTabsChange = (event, value) => {
    this.props.searchReset();
    this.setState({ value });
  };

  handleTabsChangeIndex = index => {
    this.setState({ value: index });
  };

  onAddClick = () => {
    this.setState({
      openDialog: true,
      dialogOption: 'addStation',
      dialogTitle: "Add Station",
      dialogFullscreen: false,
      selectedCampaign: null,
      selectedAdsCampaign: null,
      stationName: ''
    });
  }

  onRefreshClick = (id) =>{

    if(directoryMode[0].mode){
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station/' + id ).update({ push : true, message: "push" })
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('station/' + id ).update({ push_date: firebase.database.ServerValue.TIMESTAMP })
    }
    else
    {
      app.database().ref('push_station/' + id ).update({ push : true, message: "push" })
      app.database().ref('station/' + id ).update({ push_date: firebase.database.ServerValue.TIMESTAMP })
    }
  }

  onRefreshWithoutDeleteClick = (id) =>{
    if(directoryMode[0].mode){
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station/' + id ).update({ push : true, message: "no_delete_push" })
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('station/' + id ).update({ push_date: firebase.database.ServerValue.TIMESTAMP })
    }
    else
    {
      app.database().ref('push_station/' + id ).update({ push : true, message: "no_delete_push" })
      app.database().ref('station/' + id ).update({ push_date: firebase.database.ServerValue.TIMESTAMP })
    }
    
  }

  onRemoteControl = (id) =>{

    if(directoryMode[0].mode){
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station/' + id ).update({ remote : true })
    }
    else
    {
      app.database().ref('push_station/' + id ).update({ remote : true })
    }
    

    this.setState({
      openDialog: true,
      dialogOption: 'remoteControl',
      dialogTitle: "Remote Control",
      dialogFullscreen: true,
      stationName: '',
      selectedId: id,
    });
  }

  onUpdateClick = (id) =>{

    if(directoryMode[0].mode){
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station/' + id ).update({ push : true, message: "clear_push" })
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('station/' + id ).update({ push_date: firebase.database.ServerValue.TIMESTAMP })
    }
    else
    {
      app.database().ref('push_station/' + id ).update({ push : true, message: "clear_push" })
      app.database().ref('station/' + id ).update({ push_date: firebase.database.ServerValue.TIMESTAMP })
    }
    
  }

  onClearRefreshClick = (id) =>{
    this.setState({
      openDialog: true,
      dialogTitle: "Push & Clear Station",
      selectedId: id,
      dialogOption: 'clear',
      dialogFullscreen: false
    });
  }

  onRestartClick = (id) =>{
    this.setState({
      openDialog: true,
      dialogTitle: "Restart Station",
      selectedId: id,
      dialogOption: 'restart',
      dialogFullscreen: false
    });
  }

  handleClearStation = () => {
    if(directoryMode[0].mode){
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station/' + this.state.selectedId ).update({ push : true, message: "clear_push" })
    }
    else
    {
      app.database().ref('push_station/' + this.state.selectedId ).update({ push : true, message: "clear_push" })
    }
    

    this.setState({
      openDialog: false,
      selectedId: '',
      selected: []
    });
  }

  onResetClick = (id) =>{
    this.setState({
      openDialog: true,
      dialogTitle: "Reset Station",
      selectedId: id,
      dialogOption: 'reset',
      dialogFullscreen: false
    });
  }

  handleResetStation = () => {
    if(directoryMode[0].mode){
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station/' + this.state.selectedId ).update({ push : true, message: "reset" })
    }
    else
    {
      app.database().ref('push_station/' + this.state.selectedId ).update({ push : true, message: "reset" })
    }
    

    this.setState({
      openDialog: false,
      selectedId: '',
      selected: []
    });
  }

  handleRestartStation = () => {
    if(directoryMode[0].mode){
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station/' + this.state.selectedId ).update({ push : true, message: "restart" })
    }
    else
    {
      app.database().ref('push_station/' + this.state.selectedId ).update({ push : true, message: "restart" })
    }
    

    this.setState({
      openDialog: false,
      selectedId: '',
      selected: []
    });
  }

  componentWillMount(){
    let ref = directoryMode[0].mode ? app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('campaign') : app.database().ref('campaign');

    ref.on('value', snapshot => {
      const campaignData = [];

      snapshot.forEach(childSnapshot => {
        const item = childSnapshot.val();
        item.key = childSnapshot.key;

        campaignData.push({ id:item.key, name: item.name, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name, content: item.content });
        
      });

      this.setState({ campaignData });
    });

    let ads_ref = directoryMode[0].mode ? app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('campaign_ads') : app.database().ref('campaign_ads');

    ads_ref.on('value', snapshot => {
      const campaignAdsData = [];

      snapshot.forEach(childSnapshot => {
        const item = childSnapshot.val();
        item.key = childSnapshot.key;

        campaignAdsData.push({ id:item.key, name: item.name, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name, content: item.content });
        
      });

      this.setState({ campaignAdsData });
    });

    let zoneRef = directoryMode[0].mode ? app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('station_zone') :  app.database().ref('station_zone');

    zoneRef.on('value', snapshot => {
      const zoneCollection = [];

      snapshot.forEach(childSnapshot => {
        const item = childSnapshot.val();
        item.key = childSnapshot.key;
        
        zoneCollection.push({ id:item.key, name: item.name, created: item.created, total: item.total, sub_zone: item.sub_zone });
        
      });

      this.setState({ zoneCollection });
    });

    let refpush = directoryMode[0].mode ? app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station') : app.database().ref('push_station');

      refpush.on('value', pushSnapshot => {
        const pushStationData = [];
        
        pushSnapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;

          pushStationData.push({ 
            id:item.key, 
            volume: item.volume, 
            message: item.message, 
            brightness: item.brightness, 
          });
        
        });

        this.setState({  pushStationData });
      });

    
  }

  handleSelectedCampaign = id => {

    const currentIndex = this.state.campaignData.map(val => { return val.id}).indexOf(id)

    this.setState({ openSecondDialog: false,  selectedCampaign: this.state.campaignData[currentIndex]});
  }

  handleSelectedThirdCampaign = id => {

    const currentIndex = this.state.campaignAdsData.map(val => { return val.id}).indexOf(id)

    this.setState({ openThirdDialog: false,  selectedAdsCampaign: this.state.campaignAdsData[currentIndex]});
  }

  sliderHandleMouseUp = (id) => (event, newValue) =>{
    if(directoryMode[0].mode){
      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('push_station/' + id ).update({  volume: newValue.toString() })
    }
    else
    {
      app.database().ref('push_station/' + id ).update({  volume: newValue.toString() })
    }
    
  }
  
  render() {
    const { classes, rows, data, theme } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div className={classes.root}>
        <AppBar className={classes.AppBar} position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleTabsChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab className={classes.tab} disableRipple label="Station List" />
            <Tab className={classes.tab} disableRipple label="Zones" />
          </Tabs>
        </AppBar>
        <Dialog
            onClose={this.handleDialogThirdClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openThirdDialog}
            maxWidth='xl'
          >
          <DialogTitle id="customized-dialog-title" onClose={this.handleDialogThirdClose}>
            Campaign List
          </DialogTitle> 
          <DialogContent >
            <CustomizedCampaignList 
              rows={[
                { id: 'name', numeric: false, disablePadding: false, label: 'Campaign Name', sortable: true },
                { id: 'template', numeric: false, disablePadding: false, label: 'Template', sortable: true },
              ]} 
              data={this.state.campaignAdsData}
              orderBy={'created'}
              movefile={false}
              dataName='campaign_ads'
              handleSelectedCampaign={this.handleSelectedThirdCampaign}/>
          </DialogContent>
        </Dialog>
        <Dialog
            onClose={this.handleDialogClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openSecondDialog}
            maxWidth='xl'
          >
          <DialogTitle id="customized-dialog-title" onClose={this.handleDialogClose}>
            Campaign List
          </DialogTitle> 
          <DialogContent >
            <CustomizedCampaignList 
              rows={[
                { id: 'name', numeric: false, disablePadding: false, label: 'Campaign Name', sortable: true },
                { id: 'template', numeric: false, disablePadding: false, label: 'Template', sortable: true },
              ]} 
              data={this.state.campaignData}
              orderBy={'created'}
              movefile={false}
              dataName='campaign'
              handleSelectedCampaign={this.handleSelectedCampaign}/>
          </DialogContent>
        </Dialog>
        <Dialog
            fullScreen={this.state.dialogFullscreen}
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openDialog}
            maxWidth='xl'
            TransitionComponent={Transition}
          >
          {this.state.dialogFullscreen ? 
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={classes.flex}>
                  {this.state.dialogTitle}
                </Typography>
              </Toolbar> 
            </AppBar>: 
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
              {this.state.dialogTitle}
            </DialogTitle> 
          }
          {this.renderDialog()}
        </Dialog>
        {this.state.value === 0 && <TabContainer dir={theme.direction}>
            <EnhancedTableToolbar numSelected={selected.length} onDeleteAllClick={this.onDeleteAllClick} movefile={this.props.movefile} onAddClick={this.onAddClick} onMoveAllClick={this.onMoveAllClick} />
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={data.length}
                  rows={rows}
                />

                <TableCell padding={'none'}  colSpan={6}>
                    <FormControl style={{ marginTop:10 }}  fullWidth >
                      <InputLabel style={{ marginLeft:20 }}>Search</InputLabel>
                      <Input onChange={(e) => {this.props.search(e)}}
                        id="input-with-icon-adornment"
                        startAdornment={
                          <InputAdornment style={{ marginLeft:20 }} position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                </TableCell>
               
                <TableBody>
                  {stableSort(data, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {
                      const isSelected = this.isSelected(n.id);

                      let selectedCampaign = 'none'
                      let selectedAdsCampaign = 'none'
                      let totalCampaignContent = 'null'
                      const currentCampaignIndex = this.state.campaignData.map(val => { return val.id}).indexOf(n.campaign)
                      const currentAdsCampaignIndex = this.state.campaignAdsData.map(val => { return val.id}).indexOf(n.campaign_ads)
                      if(currentCampaignIndex !== -1){
                        selectedCampaign = this.state.campaignData[currentCampaignIndex].name
                        
                        totalCampaignContent = this.state.campaignData[currentCampaignIndex].content[0].length
                      }

                      if(currentAdsCampaignIndex !== -1){
                        selectedAdsCampaign = this.state.campaignAdsData[currentAdsCampaignIndex].name
                      }

                      const currentZoneIndex = this.state.zoneCollection.map(val => { return val.id}).indexOf(n.zone)

                      let selectedzone = 'none'
                      if(currentZoneIndex !== -1){
                        selectedzone = this.state.zoneCollection[currentZoneIndex].name;
                      }

                      const currentPushIndex = this.state.pushStationData.map(val => { return val.id}).indexOf(n.id)

                      return (
                        
                        [<TableRow
                          hover
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.id}
                          selected={isSelected}
                        >
                          <TableCell onClick={event => this.handleClick(event, n.id)} padding="checkbox">
                            <Checkbox checked={isSelected} />
                          </TableCell>

                          <TableCell onClick={event => this.handleToggle(event, n.id)} align="left" padding="none">{n.name}</TableCell>
                          
                          {n.connected ? <TableCell onClick={event => this.handleToggle(event, n.id)} padding={'default'} align="left" padding="default"><CheckedCircle style={{ color: 'green'}} fontSize="small" /></TableCell> :
                          <TableCell onClick={event => this.handleToggle(event, n.id)} padding={'default'} align="left" padding="default"><HighlightOff color="secondary"  fontSize="small" /></TableCell>
                          }

                        {!this.props.isMobile ? n.is_playing ? <TableCell onClick={event => this.handleToggle(event, n.id)} padding={'default'} align="left" padding="default"><CheckedCircle style={{ color: 'green'}} fontSize="small" /></TableCell> :
                      <TableCell onClick={event => this.handleToggle(event, n.id)} padding={'default'} align="left" padding="default"><HighlightOff color="secondary"  fontSize="small" /></TableCell>
                       : ""}
                          
                          <TableCell onClick={event => this.handleToggle(event, n.app_version)} align="left" padding="default">{n.app_version}</TableCell>

                        {!this.props.isMobile ? <TableCell align="left" padding="none" >
                        <div className={classes.actionButtons}>
                                <Tooltip title="Refresh">
                                    <IconButton onClick={() => this.onRefreshClick(n.id) }>
                                  
                                        <RefreshIcon fontSize="small" />
                                      
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit">
                                <IconButton onClick={() => this.onEditClick(n.id) }>
                                
                                    <EditIcon fontSize="small" />
                                    
                                </IconButton>
                                </Tooltip>
                                <Tooltip title="Info">
                                <IconButton onClick={() => this.onInfoClick(n.id) }>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                                </Tooltip>
                                { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ?  <Tooltip title="Delete">
                                <IconButton onClick={() => this.onDeleteClick(n.id) }>
                                
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                </Tooltip>: null}
                            </div>
                        </TableCell> : ''}
                        </TableRow>, 
                        <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                          <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Id</TableCell>
                          <TableCell padding={'none'} colSpan={11}>{n.id}</TableCell>
                        </TableRow>, 
                        <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                          <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Campaign</TableCell>
                          <TableCell padding={'none'} colSpan={11}>{selectedCampaign}</TableCell>
                        </TableRow>,
                        <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                          <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Banner</TableCell>
                          <TableCell padding={'none'} colSpan={11}>{selectedAdsCampaign}</TableCell>
                        </TableRow>,
                        <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                          <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Created by</TableCell>
                          <TableCell padding={'none'} colSpan={11}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(n.created)}</TableCell>
                        </TableRow>,
                        <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                          <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Updated by</TableCell>
                          <TableCell padding={'none'} colSpan={11}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(n.updated)}</TableCell>
                        </TableRow>,
                        <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                        <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Pushed by</TableCell>
                        <TableCell padding={'none'} colSpan={11}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(n.push_date)}</TableCell>
                      </TableRow>,
                        <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                          <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Zone</TableCell>
                          <TableCell padding={'none'} colSpan={11}>{selectedzone}</TableCell>
                        </TableRow>,
                        <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                          <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>SIM Serial</TableCell>
                          <TableCell padding={'none'} colSpan={11}>{n.sim_serial}</TableCell>
                        </TableRow>,
                        <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                          <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Total of file (App)</TableCell>
                          <TableCell padding={'none'} colSpan={11}>{n.total_file_in_app}</TableCell>
                        </TableRow>,
                        <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                          <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Total of file (campaign)</TableCell>
                          <TableCell padding={'none'} colSpan={11}>{totalCampaignContent}</TableCell>
                        </TableRow>,
                        <TableRow style={{ display: this.state.selectedTableCell === n.id && this.props.isMobile ? '': 'none', backgroundColor: '#f1f1f1'}}>
                          <TableCell padding={'default'} colSpan={12}>
                            <div className={classes.actionButtons}>
                                <IconButton onClick={() => this.onRefreshClick(n.id) }>
                                    <RefreshIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={() => this.onRestartClick(n.id) }>
                                    <ClearIcon fontSize="small"/>
                                </IconButton>
                                <IconButton onClick={() => this.onEditClick(n.id) }>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                <Tooltip title="Info">
                                <IconButton onClick={() => this.onInfoClick(n.id) }>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                                </Tooltip>
                                { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ?  <IconButton onClick={() => this.onDeleteClick(n.id) }>
                                    <DeleteIcon fontSize="small" />
                                </IconButton> : null }
                            </div>
                          </TableCell>
                        </TableRow>]
                      );
                      
                      
                    })}
                    
                  
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[20, 50, 200]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        </TabContainer> }
          {this.state.value === 1 && <TabContainer dir={theme.direction}>
              <CustomizedStationZoneTable 
                rows={this.props.isMobile ? [
                  { id: 'name', numeric: false, disablePadding: true, label: 'Zone Name', sortable: true },
                  { id: 'total', numeric: false, disablePadding: false, label: 'Total Station', sortable: true },
                ] : [
                  { id: 'name', numeric: false, disablePadding: true, label: 'Zone Name', sortable: true },
                  { id: 'total', numeric: false, disablePadding: false, label: 'Total Station', sortable: true },
                  { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
                ]} 
                data={this.state.zoneCollection}
                orderBy={'created'}
                zoneCollection={this.state.zoneCollection}
                movefile={false}
                dataName='station_zone'
                isMobile={this.props.isMobile}/>
            </TabContainer>}
        
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EnhancedTable);