import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "react-router-dom";
import { DashboardRounded, TvRounded, PermMediaRounded, PictureInPictureRounded, WebRounded, CellWifiRounded, ListAltRounded, PersonRounded, SettingsRounded, ExitToAppRounded } from '@material-ui/icons';
import app from "../firebase/base";
import logo from '../assets/logo.png';
import firebase from "firebase";
import SyncRounded from '@material-ui/icons/SyncRounded';
import { directoryMode } from '../firebase/config';
import HelpIcon from '@material-ui/icons/Help';

const drawerWidth = 200;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class Navigation extends Component {

  state = {
    open: false,
    appBarTitle:'Content',
    isMobile: false
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  navigateScreen = (index) => {
    if(index == 1){
      app.auth().signOut();
    }
    else
    {
      //window.location.href = '/stations';
    }
    
  }

  componentWillMount(){

    if(window.location.pathname == "/stations"){
      this.setState({ appBarTitle: "Stations" });
    }
    else if(window.location.pathname == "/contents"){
      this.setState({ appBarTitle: "Contents" });
    }
    else if(window.location.pathname == "/campaigns"){
      this.setState({ appBarTitle: "Campaigns" });
    }
    else if(window.location.pathname == "/campaigns-ads"){
      this.setState({ appBarTitle: "Campaigns Ads" });
    }

    const windowSize = window.innerWidth;
    if(windowSize < 800){
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }

    window.addEventListener("resize", this.handleResize );
  }

  handleResize  = () => {
    const windowSize = window.innerWidth;
    if(windowSize < 800){
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }
  
  render() {

    const { classes, theme } = this.props;
    const { open } = this.state;
    const icons = [<PermMediaRounded />, <TvRounded />, <WebRounded/>, <PictureInPictureRounded/>, <WebRounded/>, <CellWifiRounded/>, <ListAltRounded/>, <PersonRounded/>]
    const secondaryIcons = [<HelpIcon/>, <ExitToAppRounded/>]

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar>
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h7" color="inherit" noWrap>
              {this.state.appBarTitle}
            </Typography>
          </Toolbar>
        </AppBar>


        <Drawer
          className={classes.drawer}
          variant={this.state.isMobile ? "persistant" :"permanent"}
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={this.handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            
          <img width="100" src={logo} alt="Logo" />
            {
              this.state.isMobile ?
              <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            :<IconButton style={{ opacity: 0 }} onClick={this.handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
            }
            
          </div>
          <Divider />
             <Typography variant="h5" color="inherit" style={{ padding: 15, fontWeight: 'bold' }} noWrap>
              {directoryMode[0].mode ? 'Interactive' : 'Digital Signage'}
            </Typography>
          <Divider />
          <List>
          {directoryMode[0].mode ? ['Contents', 'Stations', 'Campaigns', 'Banner'].map((text, index) => (
              <ListItem button component={Link} to={text === 'Contents' ? '/' : '/' + text.toLowerCase()} key={text} onClick={()=> this.setState({ open: false, appBarTitle: text })}>
                <ListItemIcon>{icons[index]}</ListItemIcon>
                <ListItemText primary={<Typography style={{ fontWeight:'500', color: '#4d4c4c' }}>{text}</Typography>} />
              </ListItem>
            )) : ['Contents', 'Stations', 'Campaigns'].map((text, index) => (
              <ListItem button component={Link} to={text === 'Contents' ? '/' : '/' + text.toLowerCase()} key={text} onClick={()=> this.setState({ open: false, appBarTitle: text })}>
                <ListItemIcon>{icons[index]}</ListItemIcon>
                <ListItemText primary={<Typography style={{ fontWeight:'500', color: '#4d4c4c' }}>{text}</Typography>} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {['Help','Log Out'].map((text, index) => (
              <ListItem button key={text} component={Link} to={index == 1 ? undefined : '/help'} onClick={()=> this.navigateScreen(index) }>
                <ListItemIcon>{secondaryIcons[index]}</ListItemIcon>
                <ListItemText primary={<Typography style={{ fontWeight:'500',color: '#4d4c4c' }}>{text}</Typography>} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}

        >
          <div className={classes.drawerHeader} />
          {this.props.components}
        </main>
      </div>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Navigation);
